import React from "react";
import { css, Global } from "@emotion/react";
import { MEDIA_QUERIES } from "~utils/helpers";

import LarsseitMediumEOT from "~assets/fonts/Larsseit-Medium.eot";
import LarsseitMediumOTF from "~assets/fonts/Larsseit-Medium.otf";
// import LarsseitMediumSVG from "~assets/fonts/Larsseit-Medium.svg";
import LarsseitMediumTTF from "~assets/fonts/Larsseit-Medium.ttf";
import LarsseitMediumWOFF from "~assets/fonts/Larsseit-Medium.woff";

import LarsseitEOT from "~assets/fonts/Larsseit.eot";
import LarsseitOTF from "~assets/fonts/Larsseit.otf";
// import LarsseitSVG from "~assets/fonts/Larsseit.svg";
import LarsseitTTF from "~assets/fonts/Larsseit.ttf";
import LarsseitWOFF from "~assets/fonts/Larsseit.woff";

import zoomProNarrowBoldEOT from "~assets/fonts/ZoomPro-NarrowBold.eot";
import zoomProNarrowBoldOTF from "~assets/fonts/ZoomPro-NarrowBold.otf";
// import zoomProNarrowBoldSVG from "~assets/fonts/ZoomPro-NarrowBold.svg";
import zoomProNarrowBoldTTF from "~assets/fonts/ZoomPro-NarrowBold.ttf";
import zoomProNarrowBoldWOFF from "~assets/fonts/ZoomPro-NarrowBold.woff";

const Fonts = () => (
  <Global
    styles={[
      css`
        @font-face {
          font-family: "Zoom Pro Narrow Bold";
          src: url(${zoomProNarrowBoldWOFF}) format("woff"),
            url(${zoomProNarrowBoldTTF}) format("truetype"),
            url(${zoomProNarrowBoldOTF}) format("opentype"),
            url(${zoomProNarrowBoldEOT}) format("embedded-opentype");
          font-display: block;
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: "Larsseit";
          src: url(${LarsseitWOFF}) format("woff"),
            url(${LarsseitTTF}) format("truetype"),
            url(${LarsseitOTF}) format("opentype"),
            url(${LarsseitEOT}) format("embedded-opentype");
          font-display: block;
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: "Larsseit Medium";
          src: url(${LarsseitMediumWOFF}) format("woff"),
            url(${LarsseitMediumTTF}) format("truetype"),
            url(${LarsseitMediumOTF}) format("opentype"),
            url(${LarsseitMediumEOT}) format("embedded-opentype");
          font-display: block;
          font-weight: medium;
          font-style: normal;
        }

        .a1 {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 25vw;
          line-height: 1.25;
          letter-spacing: -0.01em;
        }

        .d1 {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 23vw;
          line-height: 80%;
          letter-spacing: -0.01em;
        }

        .d2 {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 21.1vw;
          line-height: 80%;
          letter-spacing: -0.01em;
        }

        .d3 {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 20vw;
          line-height: 84%;
          letter-spacing: -0.01em;
        }

        .h1 {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 68px;
          line-height: 58.48px;
          letter-spacing: -0.01em;
        }

        .h2 {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 42px;
          line-height: 36.12px;
          letter-spacing: -0.01em;
        }

        .b1 {
          font-family: "Larsseit Medium";
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0em;
        }

        .b2 {
          font-family: "Larsseit";
          font-size: 14px;
          line-height: 17.5px;
          letter-spacing: 0em;
        }

        .button {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 20px;
          line-height: 16.8px;
          letter-spacing: 0.02em;
        }

        .tag {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 16px;
          line-height: 13.44px;
          letter-spacing: 0.02em;
        }

        .tag-small {
          font-family: "Zoom Pro Narrow Bold";
          font-size: 10px;
          line-height: 8.4px;
          letter-spacing: 0.02em;
        }

        ${MEDIA_QUERIES.tablet} {
          .d1 {
            font-size: 17.36vw;
          }

          .d2 {
            font-size: 13.98vw;
          }

          .d3 {
            font-size: 8.33vw;
          }
        }

        ${MEDIA_QUERIES.desktop} {
          .a1 {
            font-size: 20vw;
          }

          .d1 {
            font-size: 17.36vw;
          }

          .d2 {
            font-size: 13.98vw;
          }

          .d3 {
            font-size: 8.33vw;
          }

          .h1 {
            font-size: 80px;
            line-height: 68.8px;
          }

          .h2 {
            font-size: 56px;
            line-height: 48.16px;
          }
        }

        ${MEDIA_QUERIES.wide} {
          .a1 {
            font-size: 300px;
          }

          .d1 {
            font-size: 250px;
          }

          .d2 {
            font-size: 202px;
          }

          .d3 {
            font-size: 120px;
          }
        }
      `
    ]}
  />
);

export default Fonts;
