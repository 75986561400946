import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FormMailChimp, Go, Grid, Input } from "~components";
import { useSanityGlobals } from "~hooks";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Arrow } from "~assets/icons/arrow.svg";
import { ReactComponent as SpoonsDesktop } from "~assets/images/footer-static-desktop.svg";
import { ReactComponent as SpoonsMobile } from "~assets/images/footer-static-mobile.svg";

//
// CSS

const Wrapper = styled.footer`
  position: relative;
  padding: 3.5rem 0 0.5rem;
  background: var(--color-black);
  color: var(--color-white);

  ${MEDIA_QUERIES.desktop} {
    padding: 2rem 0 0;
  }
`;

const ColumnLeft = styled.article`
  grid-column: span 12 / span 12;
  position: relative;
  margin-bottom: 3.5rem;

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 8 / span 8;
    margin-bottom: 0;
  }
`;

const ColumnRight = styled.article`
  grid-column: span 12 / span 12;
  position: relative;

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 4 / span 4;
  }
`;

const EmailInput = `
  width: 100%;
  height: 3rem;
  border-radius: 1.5rem;
  border: none;
  background: var(--color-white);
  color: var(--color-black);
  padding: 0.125rem 1.5rem 0;

  &::-webkit-input-placeholder {
    color: var(--color-black);
  }

  &::-moz-placeholder {
    color: var(--color-black);
  }

  &:-ms-input-placeholder {
    color: var(--color-black);
  }

  &:-moz-placeholder {
    color: var(--color-black);
  }
`;

const LinkList = styled.ul`
  width: 33.33333%;
  position: relative;

  ${MEDIA_QUERIES.desktop} {
    width: 25%;
  }
`;
const LinkText = styled.p`
  padding: 0.25rem 0;
`;

const SubmitInputWrapper = styled.div`
  width: 4rem;
  height: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
`;

const SpoonContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 1.5rem;

  ${MEDIA_QUERIES.desktop} {
    margin-top: 4rem;
  }
`;

//
// JSX

const Footer = () => {
  const { sanityGlobals } = useSanityGlobals();

  return (
    <Wrapper>
      <Grid>
        <ColumnLeft>
          <Go to="/">
            <h3
              className="h1"
              css={css`
                text-transform: uppercase;
              `}
            >
              Denada.
            </h3>
            <h3
              className="h1"
              css={css`
                text-transform: uppercase;
              `}
            >
              You’re welcome.
            </h3>
          </Go>
        </ColumnLeft>

        <ColumnRight>
          <p className="b1">
            Get the inside scoop on new flavours, offers, and stockists!
          </p>

          <FormMailChimp
            _css={css`
              position: relative;
              margin-top: 1.5rem;
            `}
          >
            <Input
              className="b1"
              name="email"
              placeholder="Enter email*"
              required
              type="email"
              _css={css`
                ${EmailInput}
              `}
            />

            <SubmitInputWrapper>
              <SubmitInput type="submit" />

              <Arrow
                css={css`
                  transform: rotate(-90deg);
                  width: 1.5rem;
                `}
                stroke="black"
              />
            </SubmitInputWrapper>
          </FormMailChimp>

          <div
            css={css`
              width: 100%;
              display: flex;
              margin-top: 2rem;
            `}
          >
            {sanityGlobals.footerLinks.map((footerLink, linkIndex, links) => {
              if (linkIndex % 2 === 0) {
                return (
                  <LinkList key={`${footerLink._key}-${links[linkIndex]._key}`}>
                    <li>
                      <Go to={footerLink.url}>
                        <LinkText className="b2">{footerLink.text}</LinkText>
                      </Go>
                    </li>

                    {links?.[linkIndex + 1] && (
                      <li>
                        <Go to={links[linkIndex + 1].url}>
                          <LinkText className="b2">
                            {links[linkIndex + 1].text}
                          </LinkText>
                        </Go>
                      </li>
                    )}
                  </LinkList>
                );
              }

              return null;
            })}
          </div>

          <p
            className="b2"
            css={css`
              margin-top: 2.5rem;
            `}
          >
            © Denada 2021
          </p>
        </ColumnRight>
      </Grid>

      <SpoonContainer>
        <SpoonsDesktop
          css={css`
            width: 100%;
            display: none;

            ${MEDIA_QUERIES.desktop} {
              display: block;
            }
          `}
        />
        <SpoonsMobile
          css={css`
            width: 100%;

            ${MEDIA_QUERIES.desktop} {
              display: none;
            }
          `}
        />
      </SpoonContainer>
    </Wrapper>
  );
};

export default Footer;
